<template>
  <div>
    <div v-if="!isMobile">
      <hendtitle title="新闻" address="" :imgurl="newsbg1"></hendtitle>
      <div class="pt65 pb65 flex flex-wrap flex-between box1200auto">
        <!-- ..... -->
        <div v-for="(item, index) in newList" :key="index">
          <newsitem :item="item"></newsitem>
        </div>
      </div>
    </div>
    <div v-if="isMobile">
      <div class="bg-hui">
        <mobilehendtitle title="新闻" :imgurl="newsbg1"></mobilehendtitle>
        <div class="pl15 pr15">
          <div v-for="(item, index) in newList" :key="index">
            <mobilecontentcard :item="item"></mobilecontentcard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
// s手机

import mobilecontentcard from "@/components/mobile/mobilecontentcard";

import hendtitle from "@/components/pc/hendtitle";
import newsbg1 from "@/assets/images/news/p1.jpg";
import mobilehendtitle from "@/components/pc/mobilehendtitle";
import newsitem from "@/components/pc/newsbox";

import newspic3 from "@/assets/images/newsimg/考察.jpg";
import newspic4 from "@/assets/images/newsimg/四川考察.jpg";
import newspic5 from "@/assets/images/newsimg/30亿元.png";
import newspic6 from "@/assets/images/newsimg/会见董事长.jpg";
import newspic7 from "@/assets/images/newsimg/抗疫物资.jpg";
import newspic8 from "@/assets/images/newsimg/华为.jpg";

import newspic9 from "@/assets/images/newsimg/襄阳1.jpg";
import newspic10 from "@/assets/images/newsimg/日喀则市.jpg";
import newspic11 from "@/assets/images/newsimg/藏青工业园区.jpg";
import newspic12 from "@/assets/images/newsimg/卓阳集团应邀参加西藏.jpg";
import newspic13 from "@/assets/images/newsimg/遂宁市委常委.jpg";
import newspic14 from "@/assets/images/newsimg/遵守安全生产法.jpg";
import newspic15 from "@/assets/images/newsimg/第六届企业论坛.jpg";
import newspic16 from "@/assets/images/newsimg/四川省政府驻沪办杨忠副主任一行莅临集团走访慰问.jpg";
import newspic17 from "@/assets/images/newsimg/慰问一线基层员工并转达亲切问候.jpg";

import newspic18 from "@/assets/images/newsimg/湖北南漳县100亿储能基地项目.jpg";

import newspic19 from "@/assets/images/newsimg/640.jpg";
import newspic20 from "@/assets/images/newsimg/newspic20.jpg";
import newspic21 from "@/assets/images/newsimg/newspic21.jpg";
import newspic22 from "@/assets/images/newsimg/newspic22.jpg";
import newspic23 from "@/assets/images/newsimg/newspic23.jpg";
export default {
  components: {
    hendtitle,
    newsitem,
    mobilehendtitle,
    mobilecontentcard,
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  data() {
    return {
      newsbg1: newsbg1,
      newList: [
        {
          id: 23,
          title: "卓阳集团应邀参加贵州省对接长三角产业协作推介大会",
          date: " 2023-06-02",
          imgurl: newspic23,
          pagepath: "https://mp.weixin.qq.com/s/2hqycIJKjWHPzgz3ENGp9Q",
        },
        {
          id: 22,
          title: "上海市江西商会执行会长罗国庆一行莅临卓阳集团考察指导",
          date: "2023-04-20",
          imgurl: newspic22,
          pagepath: "https://mp.weixin.qq.com/s/nkdyD4uPXM_nu4r8gjxDgg",
        },
        {
          id: 21,
          title: "江西省南丰县委书记叶峰一行莅临卓阳集团考察指导",
          date: "2023-03-09",
          imgurl: newspic21,
          pagepath: "https://mp.weixin.qq.com/s/ROVGoWoFwwvPWaSz3ilImA",
        },
        {
          id: 20,
          title: "贵州省黔南州平塘县委书记莫君锋一行莅临卓阳集团考察指导",
          date: "2023-02-22",
          imgurl: newspic20,
          pagepath: "https://mp.weixin.qq.com/s/PaY_EGIs_NYR6xWKKnIAqw",
        },
        {
          id: 19,
          title: "四川省达州市通川区覃永利区长一行莅临卓阳集团考察指导",
          date: "2023-02-13",
          imgurl: newspic19,
          pagepath: "https://mp.weixin.qq.com/s/gMVoBu5TjAtc1S8J_WJC1w",
        },
        {
          id: 18,
          title: "卓阳集团在湖北南漳县100亿储能基地项目开工仪式圆满举行",
          date: "2023-02-03",
          imgurl: newspic18,
          pagepath: "https://mp.weixin.qq.com/s/ZcaAsckDghX36dGO6Hx5Qg",
        },
        {
          id: 17,
          title:
            "受卓阳集团董事长黄俊先生委托，吴凌云看望慰问一线基层员工并转达亲切问候",
          date: "2023-01-09",
          imgurl: newspic17,
          pagepath: "https://mp.weixin.qq.com/s/PNLKOe-fdAhm9ot0MqDNow",
        },
        {
          id: 16,
          title: "四川省政府驻沪办杨忠副主任一行莅临集团走访慰问",
          date: "2023-01-09",
          imgurl: newspic16,
          pagepath: "https://mp.weixin.qq.com/s/ns4cvyP-QyLuWkL1D6mNBw",
        },
        {
          id: 15,
          title: "卓阳集团董事长黄俊受邀出席第六届博鳌企业论坛",
          date: "2023-01-05",
          imgurl: newspic15,
          pagepath: "https://mp.weixin.qq.com/s/loepQ0YH_gK6ljbeumdCvg",
        },

        {
          id: 13,
          title:
            "遂宁市委常委、常务副市长杜海洋一行莅临卓阳能源集团总部调研指导",
          date: "2022-11-24",
          imgurl: newspic13,
          pagepath: "https://mp.weixin.qq.com/s/JGAWjxCbcK-pLKbNBXFSXA",
        },
        {
          id: 12,
          title: "卓阳集团应邀参加西藏自治区招商引资推介会暨重大项目签约仪式",
          date: " 2022-11-06",
          imgurl: newspic12,
          pagepath: "https://mp.weixin.qq.com/s/a2rdwsnW0ynylPkMwFa6YQ",
        },
        {
          id: 11,
          title: "藏青工业园区张国宏主任一行莅临卓阳集团招商考察",
          date: "2022-11-04",
          imgurl: newspic11,
          pagepath: "https://mp.weixin.qq.com/s/JZXFUogbKOgYo2vj2HlgKg",
        },
        {
          id: 10,
          title: "日喀则市委副书记彭一浩率团莅临卓阳考察招商",
          date: "2022-11-03",
          imgurl: newspic10,
          pagepath: "https://mp.weixin.qq.com/s/JHNLLnxSxvKLzCJojuvWSg",
        },
        {
          id: 9,
          title:
            "卓阳能源集团与南漳县签订100亿储能制造和风光储一体化大基地项目投资合作协议",
          date: "2022-11-02",
          imgurl: newspic9,
          pagepath: "https://mp.weixin.qq.com/s/0yHboIerEXCwftQbQRjBjw",
        },

        {
          id: 3,
          title: "贵州省六盘水市水城区委副书记、区长赵庆强率团莅临卓阳集团考察",
          date: "2022-09-05",
          imgurl: newspic3,

          pagepath: "https://mp.weixin.qq.com/s/XpmItzKW7RCrqr7QGk0adQ",
        },
        {
          id: 4,
          title: "四川省遂宁市许文强副市长率团莅临卓阳总部考察洽谈",
          date: "2022-09-04",
          imgurl: newspic4,

          pagepath: "https://mp.weixin.qq.com/s/TsG176rKci2oK88RbUyfgA",
        },
        {
          id: 5,
          title: "卓阳集团与射洪市签署30亿元新能源项目投资协议",
          date: "2022-08-28",
          imgurl: newspic5,

          pagepath: "https://mp.weixin.qq.com/s/-ABRZpRP9rd8_YfsW_9I-g",
        },
        {
          id: 6,
          title: "唐德智会见卓阳能源集团董事长黄俊一行",
          date: "2022-08-26",
          imgurl: newspic6,

          pagepath: "https://mp.weixin.qq.com/s/tyCcS8AWgfN4fzksvDZo6Q",
        },
        {
          id: 7,
          title: "西藏抗疫 卓阳同行丨卓阳集团抗疫物资紧急驰援西藏日喀则",
          date: "2022-08-24",
          imgurl: newspic7,

          pagepath: "https://mp.weixin.qq.com/s/KzzbP1qgGAO_0_XXQopbdA",
        },
        {
          id: 8,
          title: "卓阳集团与华为能源技术公司开展技术交流会",
          date: "2022-06-22",
          imgurl: newspic8,

          pagepath: "https://mp.weixin.qq.com/s/9YAv4SWSHrxwzuTDG5GCXg",
        },
        {
          id: 14,
          title: "遵守安全生产法，当好第一责任人",
          date: "2022-06-12",
          imgurl: newspic14,
          pagepath: "https://mp.weixin.qq.com/s/KIOy9Vm4VYq94AxWaGt52g",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
  
<style lang="scss" scoped>
.box1200auto {
  width: 1200px;
  margin: 0 auto;
}
</style>