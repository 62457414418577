<template>
  <div>
    <div class="hend-picture">
      <img :src="imgurl" alt="" />
      <!-- <div class="bgopty"></div> -->
      <div class="toptitle axis-center color-white">
        <div class="fs36 fw600">{{ title }}</div>
        <!-- <div class="fs20 pt20">
          <span class="fs25 iconfont icon-dizhi"></span> 当前位置 :
          <span class="changheand" @click="runpage">首页</span> >
          {{ address }}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "address", "imgurl"],
  data() {
    return {};
  },
  methods: {
    runpage() {
      this.$router.push("/home");
    },
  },
};
</script>

<style lang="scss" scoped>
.hend-picture {
  position: relative;
  width: 100%;
  height: 60vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .toptitle {
    position: absolute;
    width: 1200px;
    // margin: 0 auto;
  }
  .bgopty {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #b8b6b6, $alpha: 0.5);
  }
}
</style>
