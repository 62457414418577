<template>
  <div>
    <div class="hend-picture pt65">
      <img :src="imgurl" alt="" />
      <!-- <div class="bgopty"></div> -->
      <div class="toptitle axis-center color-white">
        <div class="fs25 pt65 fw600">{{ title }}</div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: ["title", "imgurl"],
  data() {
    return {};
  },
  methods: {
    runpage() {
      this.$router.push("/home");
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.hend-picture {
  position: relative;
  width: 100%;
  height: 25vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .toptitle {
    position: absolute;
    // width: 1200px;
    // margin: 0 auto;
  }
  .bgopty {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #b8b6b6, $alpha: 0.5);
  }
}
</style>
  