<template>
  <div class="newsbox b-b-1 line-color-shallow" @click="runpage">
    <div class="imgbox">
      <img :src="item.imgurl" alt="" />
    </div>
    <div class="piotitle">
      {{ item.title }}
    </div>
    <div class="pl15 pt15 pb20">{{ item.date }}</div>
  </div>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    runpage() {
      // this.item.
      // this.$router.push(this.item.pagepath);
      // window.location.href = this.item.pagepath;
      window.open(this.item.pagepath);
    },
  },
};
</script>

<style lang="scss" scoped>
.newsbox {
  width: 350px;
  margin-bottom: 20px;
}
.piotitle {
  font-size: 22px;
  padding: 10px 20px;
  height: 70px;
  line-height: 30px;
  letter-spacing: 3px;
  color: #666;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: ceil(2);
  line-clamp: ceil(2);
  -webkit-box-orient: vertical;
}
.imgbox {
  width: 100%;
  height: 200px;
  img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
</style>