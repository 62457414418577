<template>
  <div class="pl15 pr15 pt15 mb10 pb10 mt10 br10 bg-white" @click="runpage">
    <div class="width-full">
      <div class="">
        <div class="imgbox width-full">
          <img :src="item.imgurl" alt="" />
        </div>
        <div class="">
          <div class="contents fs18 exceed-ellipsis2 pt10">
            {{ item.title }}
          </div>

          <div class="contents fs16 pt5 color-shallow">
            {{ item.date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    runpage() {
      // this.$router.push("/home");
      window.open(this.item.pagepath);
    },
  },
};
</script>
      
      <style lang="scss" scoped>
.contents {
  // text-indent: 40px;
  letter-spacing: 1px;
  line-height: 30px;
}
.imgbox {
  width: 100%;
  height: 200px;
  // border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
</style>
      